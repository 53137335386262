import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-schools',
  templateUrl: './our-schools.component.html',
  styleUrls: ['./our-schools.component.scss']
})
export class OurSchoolsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
