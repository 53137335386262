import { Component, OnInit } from '@angular/core';
import { DataCollection } from '../data.collection';

@Component({
  selector: 'app-public-awareness',
  templateUrl: './public-awareness.component.html',
  styleUrls: ['./public-awareness.component.scss']
})
export class PublicAwarenessComponent implements OnInit {

  workCollection: any;

  constructor(private data: DataCollection) {
    this.workCollection = data;
    // console.log(this.workCollection);
  }

  ngOnInit() {
  }

}
