import { Component, OnInit } from '@angular/core';

declare var mapboxgl: any;
declare var $: any;

@Component({
  selector: 'app-get-involved',
  templateUrl: './get-involved.component.html',
  styleUrls: ['./get-involved.component.scss']
})
export class GetInvolvedComponent implements OnInit {

  constructor() { }

  ngOnInit() { 
      
        $('.button').on('click',function(event){
          var scrollTop = $(window).scrollTop(),
              topOffset = $(this).offset().top + ($(this).outerHeight()/2) - scrollTop,
              leftOffset = $(this).offset().left + ($(this).outerWidth()/2),
              $target = $($(this).attr('href'));
          event.preventDefault;
          $target.css('transform-origin',leftOffset + 'px ' + topOffset + 'px');
          $target.addClass('is-open');
        });
        
        $('.myModal').on('click',function(event){
          $(this).removeClass('is-open');
        });
    
        /*}

  ngAfterViewinit() {*/
    // let mapboxgl = null;
    const geojson = {
        type: 'FeatureCollection',
        features: [{
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [28.02275, -26.05408]
            },
            properties: {
                title: 'Joe Public',
                description: 'Joe Public United Building, Nicol Main Office Park, 2 Bruton Road, Bryanston'
            }
        }]
    };

    mapboxgl.accessToken = 'pk.eyJ1IjoiYWxkbzk5IiwiYSI6IkZ3QU9CQ3MifQ.1XORZnKK16YePRSXRv9r4g';
    let map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/aldo99/ck2a850ie57l91cnm3xrvbe2e',
        // layers: [],
        center: [28.02275, -26.05408],
        pitch: 55, // pitch in degrees
        bearing: 0, // bearing in degrees
        zoom: 17
    });
    map.addControl(new mapboxgl.NavigationControl());
    map.scrollZoom.disable();
    map.touchZoomRotate.disable();
    // Set the map BG color - because this technically has a style generated for it, I have to wait for the styles to load before I can request a change. Seems to work this way :)
    /*map.once('style.load', function(e){
        map.setPaintProperty('background', 'background-color', '#222222');
    });*/
    map.once('style.load', () => {
        map.setPaintProperty('background', 'background-color', '#eaeaea');
    });

    // add markers to map
    geojson.features.forEach(function(marker){
        // create a HTML element for each feature
        let el = document.createElement('div');
        el.className = 'contact-marker';
        // make a marker for each feature and add to the map
        new mapboxgl.Marker(el).setLngLat(marker.geometry.coordinates).addTo(map);
    });

  }

}
