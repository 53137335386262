import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-content',
  templateUrl: './about-content.component.html',
  styleUrls: ['./about-content.component.scss']
})
export class AboutContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    const gra = function(min, max) {
      return Math.random() * (max - min) + min;
      }
      const init = function(){
              let items = document.querySelectorAll('.about');
              // cssScrollSnapPolyfill()
      }
      init();
    }
}
