import { Component, OnInit } from '@angular/core';
import { TimelineMax, Power4 } from 'gsap';

declare var $: any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
    // OPEN TRIGGER
    const openTrigger = $('.menu-trigger');
    const openTriggerTop = openTrigger.find('.menu-trigger-bar.top');
    const openTriggerMiddle = openTrigger.find('.menu-trigger-bar.middle');
    const openTriggerBottom = openTrigger.find('.menu-trigger-bar.bottom');

    // CLOSE TRIGGER
    const closeTrigger = $('.close-trigger');
    const closeTriggerLeft = closeTrigger.find('.close-trigger-bar.left');
    const closeTriggerRight = closeTrigger.find('.close-trigger-bar.right');

    // LOGO CONTAINER
    const logoContainer = document.querySelector('.logo');

    // MENU
    const innerContainer = $('.inner-container');
    const menuContainer = $('.menu-container');
    const menu = $('.menu');
    const menulinks = menu.find('.navitems');
    const menuTop = $('.menu-bg.top');
    const menuMiddle = $('.menu-bg.middle');
    const menuBottom = $('.menu-bg.bottom');

    // TL
    const tlOpen = new TimelineMax({paused: true});
    const tlClose = new TimelineMax({paused: true});

    // OPEN TIMELINE
    tlOpen.add('preOpen')
    .to(openTriggerTop, 0.4, {width: '50%', transform: 'rotate(-45deg)' }, 'preOpen')
    .to(openTriggerMiddle, 0.4, {transform: 'rotate(-45deg)'}, 'preOpen')
    .to(openTriggerBottom, 0.4, {marginLeft: '50%', width: '50%', transform: 'rotate(-45deg)'}, 'preOpen')
    .to(openTriggerTop, 0.4, {x: '+80px', y: '-80px', delay: 0.1, ease: Power4.easeIn, onComplete: function(){
      closeTrigger.css('z-index', '2000');
    }}, 'preOpen')
    .to(openTriggerMiddle, 0.4, {x: '+=80px', y: '-=80px', ease: Power4.easeIn, onComplete: function(){
      openTrigger.css('visibility', 'hidden');
    }}, 'preOpen')
    .to(openTriggerBottom, 0.4, {x: '+=80px', y: '-=80px', delay: 0.2, ease: Power4.easeIn}, 'preOpen')
    .to(innerContainer, 0, {visibility: 'visible', onComplete: function(){
      logoContainer.classList.add('activemobilenav');
    }}, 'preOpen')
    .add('open', '-=0.4')
    .to(closeTrigger, 0, {visibility: 'visible'})
    .to(menuTop, 0.8, {scaleY: 1, ease: Power4.easeInOut}, 'open')
    .to(menuMiddle, 0.8, {scaleY: 1, ease: Power4.easeInOut}, 'open')
    .to(menuBottom, 0.8, {scaleY: 1, ease: Power4.easeInOut}, 'open')
    .fromTo(menu, 0.6, {y: 30, opacity: 0, visibility: 'hidden'}, {y: 0, opacity: 1, visibility: 'visible', ease: Power4.easeOut}, '-=0.2')
    .add('preClose', '-=0.8')
    .to(closeTriggerLeft, 0.8, {x: '-=100px', y: '+=100px', ease: Power4.easeOut}, 'preClose')
    .to(closeTriggerRight, 0.8, {x: '+=100px', y: '+=100px', delay: 0.2, ease: Power4.easeOut}, 'preClose');

    // CLOSE TIMELINE
    tlClose.add('close')
    .to(logoContainer, 0, {
        className: '-=activemobilenav'
    }, 'close')
    .to(menuTop, 0.2, {
        backgroundColor: '#bf1e2e', ease: Power4.easeInOut, onComplete: function() {
            // logo.css('z-index','26');
            // closeTrigger.css('z-index','2000');
            openTrigger.css('visibility', 'visible');
        }
    }, 'close')
    .to(menuMiddle, 0.2, {backgroundColor: '#bf1e2e', ease: Power4.easeInOut}, 'close')
    .to(menuBottom, 0.2, {backgroundColor: '#bf1e2e', ease: Power4.easeInOut}, 'close')
    .to(menu, 0.6, {
        y: 20, opacity: 0, ease: Power4.easeOut, onComplete: function() {
            menu.css('visibility', 'hidden');
        }
    }, 'close')
    // .to(menuTop, 0.8, {scaleY: 0, ease: Power4.easeInOut}, 'close', '+=0.2')
    .to(menuTop, 0.8, {scaleY: 0, ease: Power4.easeInOut}, 'close')
    // .to(menuMiddle, 0.8, {scaleY: 0, ease: Power4.easeInOut}, 'close', '+=0.2')
    .to(menuMiddle, 0.8, {scaleY: 0, ease: Power4.easeInOut}, 'close')
    /*.to(menuBottom, 0.8, {scaleY: 0, ease: Power4.easeInOut, onComplete: function() {
      menuTop.css('background-color','#000000');
      menuMiddle.css('background-color','#000000');
      menuBottom.css('background-color','#000000');
    }}, 'close', '+=0.2')*/
    .to(menuBottom, 0.8, {scaleY: 0, ease: Power4.easeInOut, onComplete: function() {
      menuTop.css('background-color', '#bf1e2e');
      menuMiddle.css('background-color', '#bf1e2e');
      menuBottom.css('background-color', '#bf1e2e');
    }}, 'close')
    .to(closeTriggerLeft, 0.2, {x: '+=100px', y: '-=100px', ease: Power4.easeIn}, 'close')
    .to(closeTriggerRight, 0.2, {x: '-=100px', y: '-=100px', delay: 0.1, ease: Power4.easeIn, onComplete: function(){
      closeTrigger.css('visibility', 'hidden');
    }}, 'close')
    .to(innerContainer, 0, {visibility: 'hidden'}, 'close ')
    .to(openTriggerTop, 1, {x: '-=80px', y: '+=80px', delay: 0.4, ease: Power4.easeOut}, 'close')
    .to(openTriggerMiddle, 1, {x: '-=80px', y: '+=80px', ease: Power4.easeOut}, 'close')
    .to(openTriggerBottom, 1, {x: '-=80px', y: '+=80px', delay: 0.3, ease: Power4.easeOut}, 'close')
    .to(openTriggerTop, 0.4, {width: '100%', delay: 1, transform: 'rotate(0deg)'}, 'close')
    .to(openTriggerMiddle, 0.4, {delay: 1, transform: 'rotate(0deg)'}, 'close')
    .to(openTriggerBottom, 0.4, {marginLeft: '0', width: '100%', delay: 1, transform: 'rotate(0deg)'}, 'close');

    // EVENTS
    openTrigger.on('click', () => {
        if (!document.body.classList.contains('no-overflow')) {
            document.body.classList.add('no-overflow');
        }
        if (tlOpen.progress() < 1) {
            tlOpen.play();
        } else {
            tlOpen.restart();
        }
    });

    closeTrigger.on('click', () => {
        if (document.body.classList.contains('no-overflow')) {
            document.body.classList.remove('no-overflow');
        }
        if (tlClose.progress() < 1) {
            tlClose.play();
        } else {
            tlClose.restart();
        }
    });

    menulinks.each( (index: number) => {
      $( menulinks[index] ).on('click', () => {
        if (document.body.classList.contains('no-overflow')) {
          document.body.classList.remove('no-overflow');
        }
        if (tlClose.progress() < 1) {
          tlClose.play();
        } else {
          tlClose.restart();
        }
      });
    });
  }

}
