import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { DataCollection } from '../data.collection';
import { DomSanitizer } from '@angular/platform-browser';


declare var $: any;


@Component({
  selector: 'app-public-awareness-item',
  templateUrl: './public-awareness-item.component.html',
  styleUrls: ['./public-awareness-item.component.scss']
})
export class PublicAwarenessItemComponent implements OnInit, AfterViewInit {
  

  workItem: any;
  slug: string;
  santizer: DomSanitizer;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private data: DataCollection,
    private sanitizer: DomSanitizer,
    private renderer2: Renderer2,
    // private document: DOCUMENT
  ) {
    route.params.subscribe(params => {this.slug = params['slug']});
    this.santizer = sanitizer;
    for (let w of data.dataWork) {
      if (w['slug'] == this.slug) {
        this.workItem = w;
      }
    }
  }

  ngOnInit() {
    const videoIds = [];
    for (let wc of this.workItem.carousel) {
      if (wc.link !== '' && wc.image === '') {
        videoIds.push(wc.link);
      }
    }
    if (videoIds.length > 0) {
      const s1 = this.renderer2.createElement('script');
      s1.type = 'text/javascript';
      s1.src = 'https://fast.wistia.net/assets/external/E-v1.js';
      s1.async = '';
      this.renderer2.appendChild(document.body, s1);

      for (let vi of videoIds) {
        let s2 = this.renderer2.createElement('script');
        s2.type = 'text/javascript';
        s2.src = 'https://fast.wistia.com/embed/medias/' + vi + '.jsonp';
        s2.async = '';
        this.renderer2.appendChild(document.body, s2);
      }

    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      /*$('#js-carousel').owlCarousel({
        items: 1,
        nav: false,
        dots: true,
        dotsContainer: '.work__carousel--dots-container',
        autoplay: false,
        autoplayHoverPause: true,
        loop: false
      });*/
      $('#js-carousel').slick({
          autoplay: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          appendDots: document.querySelector('.work__carousel--dots-container'),
          fade: false,
          rows: 0,
          adaptiveHeight: true
      });
      $('.slick-dots').css({'text-align':'right'})
    }, 250);
    
  }

  iframeURL(id: any) {
    // return this.sanitizer.bypassSecurityTrustUrl('https://fast.wistia.net/embed/iframe/' + id + '');
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://fast.wistia.net/embed/iframe/' + id + '');
  }

}
