import { ModelWork } from './model.work';
import { ModelBanner } from './model.banner';
import { Injectable, AfterContentInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class DataCollection {

    public dataWork: ModelWork[];
    public dataBanners: ModelBanner[];

    public constructor(){
        // get query string
       // route.params.subscribe(params => {this.language = parseInt(params['langauge'])});

        /***********************
         * 1. ENGLISH VERSION
         *********************/
        // BRANDS
        this.dataWork = [
            {
                id: 1,
                slug:   'fonts-for-the-future',
                title:  'FONTS FOR THE FUTURE',
                tags:   'Fonts, future, fundraising, handwriting',
                description: 'FONTS FOR THE FUTURE',
                highlight: 'In a non-fee-paying school like Forte High, reliant on sponsorship funding, finding extra resources and funds for support initiatives is extremely challenging.',
                image: 'assets/img/public_awareness/fonts-for-the-future.jpg',
                bodyA: '<h4>Insight</h4><p> However, there is one resource that every student has in abundance: their handwriting.</p><h4>Idea</h4><p> We asked 200 students to write down what they dreamed of becoming in the future. We then turned each student’s handwriting into a fundraising tool by converting it into a font. We compiled a book showcasing each student’s font, together with a beautiful feature piece about each one’s story and their chosen career.</p>',
                bodyB:'<h4>Result</h4><p> We sent the books to corporates and individuals as both a catalogue and collector’s item for purchase, enabling our students to take an active part in raising funds to make their own dream careers a reality.</p>',
                carousel:[
                    {
                        // link: 'https://fast.wistia.net/embed/iframe/b2lni49m9p?seo=false&videoFoam=true',
                        link: 'b2lni49m9p',
                        title: '',
                        image: ''
                    }
                    // {
                    //     link: '',
                    //     title: '',
                    //     image: 'assets/img/public_awareness/fonts-for-the-future.jpg'
                    // }
                ],
                recognition: [],
                hotButton1Hdr: '',
                hotButton1Val: '',
                hotButton2Hdr: '',
                hotButton2Val: '',
                hotButton3Hdr: '',
                hotButton3Val: '',
                hotButton4Hdr: '',
                hotButton4Val: ''
            },
            {
                id: 2,
                slug: 'space',
                title: 'SPACE',
                tags:  'Space, cosmonaut, radio transmission',
                description: 'SPACE',
                highlight: 'With many of our OSAAT students coming from child-headed homes and the majority of South African schools rated as dysfunctional, there is a severe lack of role models and guidance.',
                image: 'assets/img/public_awareness/osaat-space.jpg',
                bodyA: '<h4>Insight</h4><p>We wanted to provide our students with a mentor – someone who had already made his/her most ‘impossible’ dream come true; someone who could reignite our learners’ belief in the power of their dreams.</p><h4>Idea</h4><p>We looked to the stars to find a surprising candidate – Russian Cosmonaut, Gennady Padalka – and asked him to record a message of inspiration, live from the International Space Station. We then beamed it down in the form of a radio transmission via Radio 702, straight to the desks of the learners at Forte High School.</p>',
                bodyB:'<h4>Result</h4><p>The experience motivated the learners to re-dedicate themselves to their studies and to think beyond the bounds of what they had imagined possible for their futures. At the same time, listeners all over South Africa tuned in to experience the historic event and pledge their support for OSAAT’s vision of hope and change for the future.</p>',
                carousel: [
                    // {
                    //     // link: 'https://fast.wistia.net/embed/iframe/ex9d7okqhu?videoFoam=true',
                    //     link: '',
                    //     title: '',
                    //     image: 'assets/img/public_awareness/osaat-space.jpg'
                    // },
                    // {
                    //     // link: 'https://fast.wistia.net/embed/iframe/5hmmvp5002?videoFoam=true',
                    //     link: '',
                    //     title: '',
                    //     image: 'assets/img/public_awareness/space-radio.jpg'
                    // },
                    {
                        // link: 'https://fast.wistia.net/embed/iframe/y6tnnvqfow?videoFoam=true',
                        link: 'ddcxc6b6lu',
                        title: '',
                        image: ''
                    }
                ],
                recognition: [],
                hotButton1Hdr: '',
                hotButton1Val: '',
                hotButton2Hdr: '',
                hotButton2Val: '',
                hotButton3Hdr: '',
                hotButton3Val: '',
                hotButton4Hdr: '',
                hotButton4Val: ''
            },
            {
                id: 3,
                slug: 'english-1',
                title: 'ENGLISH 1',
                tags:  'English, competency, The Times, newspaper, experiment',
                description: 'ENGLISH 1',
                highlight: 'In a country with 11 official languages, the standardised language of tuition in most schools is English. This can be problematic because up to 90% of the population don’t speak English as a first language.',
                image: 'assets/img/public_awareness/english-1.jpg',
                bodyA: '<h4>Insight</h4><p>Learners often struggle with poor language comprehension. The problem is compounded because challenging subjects like Maths and Science are also taught in English, making the concepts more challenging to grasp at school and practically impossible to tackle at tertiary level.  </p><h4>Idea</h4><p>To raise awareness around this issue, we conducted an experiment at <i>The Times</i> newspaper, testing the level of English competency of average Grade 10 learners. Seven learners were asked to write down a press-ready article, as dictated to them. Their version of the article was then printed in the newspaper alongside the original.</p>',
                bodyB:'<h4>Result</h4><p>The learners’ version contained 314 basic grammar mistakes and revealed an alarmingly low level of comprehension, shining a light on the dire need for first language English tuition in SA schools, and appealing to the public to support OSAAT’s endeavours to improve English proficiency in our own partner schools. </p>',
                carousel:[
                    // {
                    //     // link: 'https://fast.wistia.net/embed/iframe/h55mlf44xz?videoFoam=true',
                    //     link: '',
                    //     title: '',
                    //     image: 'assets/img/public_awareness/english-1.jpg'
                    // },
                    {
                        // link: 'https://fast.wistia.net/embed/iframe/6rzmoctmcq?videoFoam=true',
                        link: '',
                        title: '',
                        image: 'assets/img/public_awareness/One-School-At-A-Time-Experiment.jpg'
                    },
                    {
                        // link: 'https://fast.wistia.net/embed/iframe/uohe8k9gvk?videoFoam=true',
                        link: 'lfq3k2gict',
                        title: '',
                        image: ''
                    }
                ],
                recognition: [],
                hotButton1Hdr: '',
                hotButton1Val: '',
                hotButton2Hdr: '',
                hotButton2Val: '',
                hotButton3Hdr: '',
                hotButton3Val: '',
                hotButton4Hdr: '',
                hotButton4Val: ''
            },
            {
                id: 4,
                slug: 'english-2',
                title: 'ENGLISH 2',
                tags:  'English, reading, competency, radio, experiment',
                description: 'ENGLISH 2',
                highlight: 'Where ‘Project: English 1’, highlighted the low levels of English comprehension that many learners struggle with, and the need for greater focus on English proficiency in South African schools, ‘Project: English 2’ aimed to find a solution.',
                image: 'assets/img/public_awareness/Lesego-reading.png',
                bodyA: '<h4>Insight</h4><p> We started with a question: what impact would it have if we could give one of our learners extra 1st language English tuition, even if it\'s just for one week?</p><h4>Idea</h4><p>We set up an experiment on national radio to find out. Every day for a week, our learner would receive extra English tuition, and each day she would also read an English radio script, broadcast on SAFM. In this way, we were able to track the improvement of the student’s English proficiency, day-by-day, live on national radio.</p>',
                bodyB:'<h4>Result</h4><p>The improvement in our student’s English, even over such a short period, was remarkable. And broadcasting the experiment to a national audience allowed us to have a large scale conversation around the need for first language English tuition in South Africa – proving that even the smallest intervention can have a massive impact.</p>',
                carousel:[
                    // {
                    //     // link: 'https://fast.wistia.net/embed/iframe/h55mlf44xz?videoFoam=true',
                    //     link: '',
                    //     title: '',
                    //     image: 'assets/img/public_awareness/Lesego-reading.png'
                    // },
                    {
                        // link: 'https://fast.wistia.net/embed/iframe/6rzmoctmcq?videoFoam=true',
                        link: 'jcblagk0c6',
                        title: '',
                        image: ''
                    }
                ],
                recognition: [],
                hotButton1Hdr: '',
                hotButton1Val: '',
                hotButton2Hdr: '',
                hotButton2Val: '',
                hotButton3Hdr: '',
                hotButton3Val: '',
                hotButton4Hdr: '',
                hotButton4Val: ''
            },
            
        ];

        this.dataBanners = [
            {
                id: 1,
                image: '/assets/img/images/banner-images/Web/Header-1_ref.jpg',
                mobileimage: '/assets/img/images/banner-images/mobile/Mob_Header-1_ref.jpg',
                h2: 'A beacon<br />of light',
                copy: 'Our purpose is to create an educational<br />system that\'s a shining example to the world.',
                layout: '',
                class: '',
                align: 'right'
            },
            {
                id: 2,
                image: '/assets/img/images/banner-images/Web/Header-2_ref.jpg',
                mobileimage: '/assets/img/images/banner-images/mobile/Mob_Header-2_ref.jpg',
                h2: 'Freedom through<br />education',
                copy: 'Our country\'s next liberation struggle should<br />be to free the minds of our youth through a<br />functional education system.',
                layout: '',
                class: '',
                align: 'right'
            },
            {
                id: 3,
                image: '/assets/img/images/banner-images/Web/Header-3_ref.jpg',
                mobileimage: '/assets/img/images/banner-images/mobile/Mob_Header-3_ref.jpg',
                h2: 'Challenging<br />the status quo',
                copy: '22&nbsp;000 of SA\'s 27&nbsp;000 schools<br />are currently rated dysfunctional.* <br> <span class="small">*The Joint Education Trust</span>',
                layout: '',
                class: '',
                align: 'right'
            },
            {
                id: 4,
                image: '/assets/img/images/banner-images/Web/Header-4_ref.jpg',
                mobileimage: '/assets/img/images/banner-images/mobile/Mob_Header-4_ref.jpg',
                h2: 'Starting<br />one school at a time',
                copy: '',
                layout: {
                    left: {
                        col: 5,
                        h3: 'Forte High School, Soweto',
                        copy: 'Current matric pass rate: 98%<br />Bachelor pass rate: 80%'
                    },
                    right: {
                        col: 7,
                        h3: 'Itirele-Zenzele Comprehensive School, Diepsloot',
                        copy: 'Current matric pass rate: 78%<br />Bachelor pass rate: 48%'
                    }
                },
                class: '',
                align: 'left'
            }
            // {
            //     id: 5,
            //     image: '/assets/img/images/banner-images/Web/v02/Header-5_ref.jpg',
            //     mobileimage: '/assets/img/images/banner-images/mobile/Mob_Header-5.png',
            //     // h1: 'Itirele-<br />Zenzele',
            //     // h2: 'Comprehensive School',
            //     // copy: 'Current matric pass rate <strong>88%</strong><br />Bachelor pass rate <strong>48%</strong><br /><strong>60</strong> distinctions',
            //     class: ''
            // },
            // {
            //     id: 6,
            //     image: '/assets/img/images/banner-images/Web/v02/Header-6_ref.jpg',
            //     mobileimage: '/assets/img/images/banner-images/mobile/Mob_Header-6.png',
            //     // h1: 'Itirele-<br />Zenzele',
            //     // h2: 'Comprehensive School',
            //     // copy: 'Current matric pass rate <strong>88%</strong><br />Bachelor pass rate <strong>48%</strong><br /><strong>60</strong> distinctions',
            //     class: ''
            // }
        ];
    }
}
