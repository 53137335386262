import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { CarouselComponent } from './carousel/carousel.component';
import { FooterComponent } from './footer/footer.component';
import { OurPartnersComponent } from './our-partners/our-partners.component';
import { SubscribeNewsletterComponent } from './subscribe-newsletter/subscribe-newsletter.component';
import { AboutContentComponent } from './about-content/about-content.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { ContactMapComponent } from './contact-map/contact-map.component';
import { Routes, RouterModule} from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { InitiativeComponent } from './initiative/initiative.component';
import { PublicAwarenessComponent } from './public-awareness/public-awareness.component';
import { PublicAwarenessItemComponent } from './public-awareness-item/public-awareness-item.component';
import { GetInvolvedComponent } from './get-involved/get-involved.component';
import { OurSchoolsComponent } from './our-schools/our-schools.component';

import { DataCollection } from './data.collection';
import { TippyModule } from 'ng-tippy';




@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    CarouselComponent,
    FooterComponent,
    OurPartnersComponent,
    SubscribeNewsletterComponent,
    AboutContentComponent,
    ContactFormComponent,
    ContactMapComponent,
    HomepageComponent,
    InitiativeComponent,
    PublicAwarenessComponent,
    PublicAwarenessItemComponent,
    GetInvolvedComponent,
    OurSchoolsComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    TippyModule
  ],
  providers: [DataCollection],
  bootstrap: [AppComponent]
})
export class AppModule { }
