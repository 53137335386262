import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-initiative',
  templateUrl: './initiative.component.html',
  styleUrls: ['./initiative.component.scss']
})
export class InitiativeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('.button').on('click',function(event){
      var scrollTop = $(window).scrollTop(),
          topOffset = $(this).offset().top + ($(this).outerHeight()/2) - scrollTop,
          leftOffset = $(this).offset().left + ($(this).outerWidth()/2),
          $target = $($(this).attr('href'));
      event.preventDefault;
      $target.css('transform-origin',leftOffset + 'px ' + topOffset + 'px');
      $target.addClass('is-open');
    });
    
    $('.myModal').on('click',function(event){
      $(this).removeClass('is-open');
    });


    
  }

}
