import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { InitiativeComponent } from './initiative/initiative.component';
import { PublicAwarenessComponent } from './public-awareness/public-awareness.component';
import { PublicAwarenessItemComponent } from './public-awareness-item/public-awareness-item.component';
import { GetInvolvedComponent } from './get-involved/get-involved.component';
import { OurSchoolsComponent } from './our-schools/our-schools.component';
import { AboutContentComponent } from './about-content/about-content.component';

const routes:Routes = [
  {path: 'about', component: AboutContentComponent},
  {path: '', component: HomepageComponent},
  {path: 'initiatives', component: InitiativeComponent},
  {path: 'our-schools', component: OurSchoolsComponent},
  {path: 'public-awareness', component: PublicAwarenessComponent},
  {path: 'public-awareness/:slug', component: PublicAwarenessItemComponent},
  {path: 'get-involved', component: GetInvolvedComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
