import { Component, OnInit, AfterViewInit, AfterContentInit } from '@angular/core';
// import { jquery} from 'jquery';
import { TimelineMax, TweenMax} from 'gsap';
import { DataCollection } from '../data.collection';

declare var $: any;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, AfterViewInit {

  bannerCollection: any;

  constructor(private bannerdata: DataCollection) {
    // TweenMax.set('.home__slider', {height: '100vh'});
    this.bannerCollection = bannerdata.dataBanners;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      $('.home__slider').slick({
        autoplay: true,
        autoplaySpeed: 7500,
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        appendDots: document.querySelector('.home__slider--dotscontainer'),
        fade: false,
        rows: 0
      });
    }, 250);
  }

  ngOnInit() {
  }

}
